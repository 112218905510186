import { Link } from "@tanstack/react-router";
import logoUrl from "../../assets/realitiq-connect-logo.png";

export const Logo = () => {
  return (
    <div className="flex-1 flex items-center justify-center p-4">
      <Link to="/" className="flex items-center space-x-2">
        <img src={logoUrl} className="App-logo" alt="logo" />
      </Link>
    </div>
  );
};

export default Logo;
