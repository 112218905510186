import Logo from "@/components/ui/logo";
import { createFileRoute } from "@tanstack/react-router";
import { register } from "@teamhanko/hanko-elements";
import { de } from "@teamhanko/hanko-elements/i18n/de";
import { en } from "@teamhanko/hanko-elements/i18n/en";
import { useEffect } from "react";

export const Route = createFileRoute("/login")({
  component: () => {
    useEffect(() => {
      register(import.meta.env.VITE_HANKO_API_URL, {
        translations: { de, en },
        fallbackLanguage: "de",
      });
    }, []);

    return (
      <div className="flex flex-col md:flex-row h-screen">
        {/* Left side - Login Form */}
        <div className="w-full md:w-1/2 p-8 flex flex-col justify-center">
          <div className="max-w-md w-full mx-auto">
            <h1 className="text-2xl font-bold mb-2">
              Willkommen bei Realitiq Connect
            </h1>
            <p className="text-gray-600 mb-8">
              Immobilienverwaltung & 3D-Erfassung
            </p>

            <hanko-auth />
          </div>

          <footer className="mt-12 text-center text-sm text-gray-500">
            <a href="#" className="mx-2 hover:underline">
              Impressum
            </a>
            <a href="#" className="mx-2 hover:underline">
              Datenschutz
            </a>
            <a href="#" className="mx-2 hover:underline">
              Über Uns
            </a>
          </footer>
        </div>

        {/* Right side - Image and Branding */}
        <div className="hidden md:flex flex-col space-y-8 md:w-1/2 bg-[url('/login-bg.jpg')] bg-cover bg-center items-center justify-center p-8">
          <div className="flex-0 w-2/3">
            <Logo />
          </div>

          <div className="text-center">
            <p className="text-3xl font-bold text-white px-10">
              Immobilienverwaltung einfach, schnell & effizient
            </p>
          </div>
        </div>
      </div>
    );
  },
});
