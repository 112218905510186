import { createFileRoute } from '@tanstack/react-router'
import { Outlet, Link } from '@tanstack/react-router'
import { Button } from '@/components/ui/button'
import { Logo } from '@/components/ui/logo'

export const Route = createFileRoute('/_authenticated/_sidebar-layout')({
  component: () => (
    <div className="flex h-screen">
      {/* Side Navigation */}
      <aside className="w-64 bg-gray-900 text-white flex flex-col">
        {/* Logo */}
        <Logo />

        {/* Navigation Links */}
        <nav className="flex flex-col p-4 space-y-2 flex-1">
          <Link to="/" className="w-full">
            <Button variant="ghost" className="w-full justify-start text-white">
              Home
            </Button>
          </Link>
          <Link to="/about" className="w-full">
            <Button variant="ghost" className="w-full justify-start text-white">
              About
            </Button>
          </Link>
          <Link to="/contact" className="w-full">
            <Button variant="ghost" className="w-full justify-start text-white">
              Contact
            </Button>
          </Link>
          {/* Add more navigation links as needed */}
        </nav>

        {/* Optional Footer */}
        <div className="p-4">
          {/* Footer content (e.g., logout button, version number) */}
        </div>
      </aside>

      {/* Main Content */}
      <main className="flex-1 p-4 overflow-auto">
        <Outlet />
      </main>
    </div>
  ),
})
