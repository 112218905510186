import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_authenticated/_sidebar-layout/')({
  loader: async ({ context: { treaty } }) => {
    return {
      user: (await treaty.user({ id: '1' }).get()).data,
    }
  },
  component: () => {
    const { user } = Route.useLoaderData()
    return <div>{user?.email}</div>
  },
})
