import { StrictMode } from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { routeTree } from "./routeTree.gen";
import "./index.css";
import { treaty } from "@elysiajs/eden";
import { Api } from "realtiq-api/src/routes";
import { ThemeProvider } from "@/components/providers/ThemeProvider";

// Create a new router instance
const router = createRouter({
  routeTree,
  context: {
    //TODO: use env variable
    treaty: treaty<Api>(import.meta.env.VITE_API_URL),
  },
});

Sentry.init({
  dsn: "https://0e01ef06ff7770d7df39c2fad9b4de74@o4507968654540800.ingest.de.sentry.io/4507968657227856",
  integrations: [Sentry.tanstackRouterBrowserTracingIntegration(router)],
  environment: import.meta.env.MODE,
  tracesSampleRate: 1.0,
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>
    </StrictMode>,
  );
}
